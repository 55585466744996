import { combineReducers } from "redux";
import mapReducer from "./mapReducer";
import sportsReducer from './sportsReducer';
import mediaReducer from './mediaReducer';
import milestoneReducer from "./milestoneReducer";
import playersReducer from "./playersReducer";

const rootReducer = combineReducers({
  mapReducer,
  sportsReducer,
  mediaReducer,
  milestoneReducer,
  playersReducer,
});

export default rootReducer;
