import { useState } from "react";
import "./navbar.css";

const AccessibilityView = () => {
  const [accessibilityView, setAccessibilityView] = useState({
    view: "A",
    className: "body",
  });

  const handleChange = (view) => {
    if (view === "A+") {
      if (
        view === "A+" &&
        accessibilityView?.className === "body-A-minus-minus"
      ) {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body-A-minus");
        setAccessibilityView({ view: "A-", className: "body-A-minus" });
      } else if (
        view === "A-" &&
        accessibilityView?.className === "body-A-minus"
      ) {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body");
        setAccessibilityView({ view: "A", className: "body" });
      } else if (accessibilityView?.view === "A+") {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body-A-plus-plus");
        setAccessibilityView({ view: view, className: "body-A-plus-plus" });
      } else {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body-A-plus");
        setAccessibilityView({ view: view, className: "body-A-plus" });
      }
    } else if (view === "A-") {
      if (
        view === "A-" &&
        accessibilityView?.className === "body-A-plus-plus"
      ) {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body-A-plus");
        setAccessibilityView({ view: "A+", className: "body-A-plus" });
      } else if (
        view === "A-" &&
        accessibilityView?.className === "body-A-plus"
      ) {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body");
        setAccessibilityView({ view: "A", className: "body" });
      } else if (accessibilityView?.view === "A-") {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body-A-minus-minus");
        setAccessibilityView({ view: view, className: "body-A-minus-minus" });
      } else {
        document.body.classList.remove(accessibilityView?.className);
        document.body.classList.add("body-A-minus");
        setAccessibilityView({ view: view, className: "body-A-minus" });
      }
    } else if (
      view === "A" &&
      accessibilityView?.className === "body-A-plus-plus"
    ) {
      document.body.classList.remove(accessibilityView?.className);
      document.body.classList.add("body-A-plus");
      setAccessibilityView({ view: view, className: "body-A-plus" });
    } else if (
      view === "A" &&
      accessibilityView?.className === "body-A-minus-minus"
    ) {
      document.body.classList.remove(accessibilityView?.className);
      document.body.classList.add("body-A-minus");
      setAccessibilityView({ view: view, className: "body-A-minus" });
    } else {
      document.body.classList.remove(accessibilityView?.className);
      setAccessibilityView({ view: view, className: "body" });
    }
  };
  return (
    <div
      className="topbar-bottom">
      <p onClick={() => handleChange("A+")}>A+</p>
      <p onClick={() => handleChange("A")}>A</p>
      <p onClick={() => handleChange("A-")}>A-</p>
    </div>
  );
};

export default AccessibilityView;
