import { useEffect } from "react";

function ScrollTopTop({ children }) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return <div>{children}</div>;
}

export default ScrollTopTop;
