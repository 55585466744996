import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  selectedLocation: "delhi",
  stadiums: [
    {
      panjim: 'https://www.google.com/maps/d/embed?mid=17BeoXTQRYUB0eb-MFompijlp5SrWeLc&ehbc=2E312F&noprof=1'
    },
    {
      mapusa: 'https://www.google.com/maps/d/embed?mid=1JRSNRpEEel6Xc9SzLLCUuLZHSx4Am_c&ehbc=2E312F&noprof=1',
    },
    {
      margao: 'https://www.google.com/maps/d/embed?mid=1GpawpP0DGeSSk8mxmtlQjqsJN3KRQjI&ehbc=2E312F&noprof=1',
    },
    {
      ponda: 'https://www.google.com/maps/d/embed?mid=111SMqlPP7_fApcVXN9_d_pNtgOGtcjw&ehbc=2E312F&noprof=1',
    },
    {
      vasco: 'https://www.google.com/maps/d/embed?mid=1bFnlFEA7eFjvr1Nrs7fT00lyakfyPys&ehbc=2E312F&noprof=1',
    },
    {
      delhi: 'https://www.google.com/maps/d/embed?mid=1bzEWQsszkVC4XEuVDw2Poma7cvd5Jq4&ehbc=2E312F&noprof=1',
    }
  ],
  hideFooter: false,
  removeHeaderAndFooter: false,
};

const mapSlice = createSlice({
  name: "mapReducer",
  initialState,
  reducers: {
    setSelectedPlace: (state, action) => {
      state.selectedPlace = action.payload;
    },
    setSelectedLocation: (state, action) => {
      state.selectedLocation = action.payload;
    },
    setHideFooter: (state, action) => {
      state.hideFooter = action.payload;
    },
    setRemoveHeaderAndFooter: (state, action) => {
      state.removeHeaderAndFooter = action.payload;
    },
  },
});

export const {
  setSelectedPlace,
  setSelectedLocation,
  setHideFooter,
  setRemoveHeaderAndFooter,
} = mapSlice.actions;

export default mapSlice.reducer;
