import { createSlice } from "@reduxjs/toolkit";
import {
  fetchMilestoneCategory,
  fetchMilestoneByCategory,
} from "api/milestoneApiService";
import { makeTextSmallAndAddHyphens } from "utils/helper";

const initialState = {
  milestoneCategory: [],
  milestones: [],
};

const milestoneSlice = createSlice({
  name: "milestoneReducer",
  initialState,
  reducers: {
    setMilestoneCategory: (state, action) => {
      state.milestoneCategory = action.payload;
    },
    setMilestones: (state, action) => {
      state.milestones = action.payload;
    },
  },
});

export const { setMilestones, setMilestoneCategory } = milestoneSlice.actions;

export const getMilestoneCategory = (param = {}) => {
  return async (dispatch) => {
    try {
      const res = await fetchMilestoneCategory();
      const filtered = res?.data?.records?.filter(
        (item) => item?.status?.toLowerCase() === "enabled"
      );
      const mapped = filtered?.map((item) => ({
        id: item?.id,
        label: item?.title,
        path: `/milestone/${item?.catCode}`,
        categoryCode: item?.catCode,
      }));
      dispatch(setMilestoneCategory(Array.isArray(mapped) ? mapped : []));
    } catch (error) {
      console.error(error);
    }
  };
};

export const getMilestones = (categoryId = "") => {
  return async (dispatch, getState) => {
    try {
      const { milestoneCategory } = getState().milestoneReducer;
      const res = await fetchMilestoneByCategory(categoryId);
      const filtered = res?.data?.records?.filter(
        (item) =>
          item?.status?.toLowerCase() === "enabled" &&
          milestoneCategory.find((cat) => cat.id == item.catId)
      );
      const mapped = filtered?.map((item) => ({
        ...item,
        imageSrc: "https://api.37nationalgamesgoa.in/news/" + item?.newsImage,
      }));
      dispatch(setMilestones(Array.isArray(mapped) ? mapped : []));
    } catch (error) {
      console.error(error);
    }
  };
};

export default milestoneSlice.reducer;
