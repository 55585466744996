import { useHistory } from "react-router-dom";

const NavLogin = () => {
  const history = useHistory();
  return (
    <div className="navbar-login cursor-pointer" onClick={()=> history.push("/")}>
      
          <a
            className="nav-link float-button"
            onClick={() => {
              window.location.href = "http://gms.37nationalgamesgoa.in/"
            }}
          >
            <span>LOGIN</span>
            <div class="liquid"></div>
          </a>
    </div>
  );
};

export default NavLogin;
