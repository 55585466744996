export function makeTextSmallAndAddHyphens(inputString) {
  if (inputString?.length)
    return inputString.toLowerCase().replace(/\s+/g, "-");
}

export function convertToTitleCase(inputText) {
  try {
    const words = inputText.split("-").map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1);
    });
    const resultText = words.join(" ");
    return resultText;
  } catch (error) {
    console.error(error);
  }
}

export function capitalizeWords(inputString) {
  try {
    let words = inputString?.trim()?.split("-").join(" ");
    words = words.replace(/\b\w/g, function (char) {
      return char.toUpperCase();
    });

    return words;
  } catch (error) {
    console.error(error);
  }
}

export const firstLetterCapital = (str) => {
  try {
    let result = "";
    if (str?.length) {
      result = str?.charAt(0)?.toUpperCase() + str?.slice(1);
    }
    return result;
  } catch (error) {
    console.error(error);
  }
};

export const getReplacedName = (item) => {
  let data = item;
  try {
    if (item.charAt(item.length - 1) === "-") {
      data = item.substr(0, item.length - 1);
    }
    return data.toLowerCase().replace("-", "_");
  } catch (error) {
    console.error(error);
  }
};

export const handleSearch = (data, keyWord) => {
  let filteredData = [];
  data?.map((item) => {
    if (item.toUpperCase().includes(keyWord.toUpperCase())) {
      filteredData.push(item);
    }
    return filteredData;
  });
  return filteredData;
};
