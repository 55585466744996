import { useEffect } from "react";
import "./ErrorPage.css";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
export default function ErrorPage(props) {
  useEffect(() => {
    const timeout = setTimeout(() => {
      window.location.href = window.location.origin;
    }, 5000);
    return () => {
      clearTimeout(timeout);
    };
  }, 5000);
  return (
    <div className={"error-page"}>
      <div className={"oops"}>Oops!</div>
      <div className={"message"}>Something went wrong...</div>
    </div>
  );
}
