import styles from "./SchedulePage.module.css";
import React from "react";
import ScrollTopTop from "components/common/scrollToTop";
import Banner from "components/common/banner";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";

export const getPropertyNoCase = (obj, prop) => {
  return obj[
    Object.keys(obj)?.find((key) =>
      prop?.toLowerCase()?.includes(key.toLowerCase())
    )
  ];
};

function ScheduleAndResults({ children }) {
  const history = useHistory();
  const states = ["Overview", "Cluster"];

  const nav_element = states.map((ele, index) => (
    <p
      key={index}
      className={styles.cities}
      onClick={() => {
        history.push(`/schedule/${ele?.toLowerCase()}`);
      }}
      style={{marginTop:'0px', marginBottom:'0px'}}
    >
      {ele}
    </p>
  ));

  return (
    <ScrollTopTop>
      <div className={styles.page}>
        <Banner text="Schedule & Results" />
        <div className={styles.navbar}>{nav_element}</div>
        {children}
      </div>
    </ScrollTopTop>
  );
}

export default ScheduleAndResults;
