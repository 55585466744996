const Banner = ({ text = "Participant Team", children, img }) => {
  return (
    <div
      style={{
        position: "relative",
      }}
    >
      <img
        src={img ? img : "/assets/images/teams.png"}
        alt="banner"
        style={{
          width: "100%",
          height: "auto",
        }}
      />
      {text && (
        <h1
          id="participantTeam"
          className="participantTeam"
          style={{
            maxWidth: "50%",
          }}
        >
          {text}
        </h1>
      )}
      {children}
    </div>
  );
};

export default Banner;
