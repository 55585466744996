/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useRef, useEffect } from "react";
import "./navbar.css";
import NavLogo from "./NavLogo";
import { useHistory } from "react-router-dom";
import NavLogin from "./NavLogin";
import goa from "assets/gamePartners/goa.png";
import IOA from "assets/gamePartners/ioa.png";
import ministry from "assets/gamePartners/ministry.png";
import sag from "assets/gamePartners/sag.png";
import Tooltip from "@mui/material/Tooltip";
import styles from "./navbar.module.css";
import { useSelector } from "react-redux";
import Wheelchair from "../../assets/svg/footer/wheelchair-white.svg";
import AccessibilityView from "./AccessibilityView";

const Navbar = () => {
  const history = useHistory();
  const [openNavbar, setOpenNavbar] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [selectedMenu, setSelectedMenu] = useState("");
  const [width, setWidth] = useState(window.innerWidth < 992);
  const [openWheelchair, setOpenWheelchair] = useState(false);
  const wrapperRef = useRef(null);

  const { milestoneCategory } = useSelector((state) => state.milestoneReducer);

  const subMenuList = [
    {
      submenu: "games",
      list: [
        { label: " Schedule & Results", path: "/schedule/overview" },
        { label: "Records", path: "/games/records" },
        { label: "Reports", path: "/games/reports" },
        { label: "Medal Tally", path: "/games/medals" },
      ],
    },
    {
      submenu: "latest",
      list: [
        { label: "News", path: "/latest/news" },
        { label: "Photos", path: "/latest/photos" },
        { label: "Videos", path: "/latest/videos" },
      ],
    },
    {
      submenu: "visit",
      list: [
        { label: "Hospitality", path: "/visit/hospitality" },
        { label: "Travel", path: "/visit/travel" },
        { label: "Khel Village", path: "/visit/khel-village" },
        { label: "36 National Games", path: "/visit/36-national-games" },
      ],
    },
    {
      submenu: "milestone",
      list: [{ label: "All", path: "/milestone/all" }, ...milestoneCategory],
    },
    {
      submenu: "abouts",
      list: [
        { label: "National Games", path: "/info/about-national-games" },
        { label: "Moga (Mascot)", path: "/info/about-moga" },
        { label: "About Committees", path: "/info/committees" },
        { label: "Goa", path: "/info/about-goa" },
        { label: "IOA", path: "/info/about-ioa" },
        { label: "Desk Notes", path: "/info/about-desk-notes" },
        { label: "Sponsor", path: "/info/about-sponsors" },
        { label: "Contact Us", path: "/info/contact-us" },
      ],
    },
  ];

  const scrollToTop = () => {
    const element = document.getElementById('home-body');
    if (element) {
      element.scrollIntoView({ top: 0, behavior: 'smooth' });
    }
  };

  const selectedSubMenuList = React.useMemo(() => {
    let data = [];
    if (isMenuOpen) {
      data = subMenuList.filter((x) => x.submenu === selectedMenu);
    }
    return data[0]?.list ? data[0]?.list : [];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMenu, isMenuOpen]);

  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        // setIsMenuOpen(false);
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [wrapperRef]);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth < 992);
      if (window.screen.width >= 1000) {
        setOpenNavbar(false);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <div className="nav-container">
        <NavLogo
          click={() => {
            setIsMenuOpen(false);
            setOpenNavbar(false);
            scrollToTop();
          }}
        />
        <nav
          className="navbar navbar-expand-lg bg-indigo relative"
          ref={wrapperRef}
        >
          <div className="container-fluid p-0">
          <div className="wheelchair">
            <img
               onClick={() => setOpenWheelchair(!openWheelchair)}
              class="show-con"
              width="30px"
              src={Wheelchair}
              title="Accessibility Icon"
              alt="Accessibility Icon"
            />
           {openWheelchair && <AccessibilityView/>}
          </div>
           {width && <button
              className="navbar-toggler navbar-light  ms-auto"
              type="button"
              style={{ outline: "none" }}
              onClick={() => setOpenNavbar(!openNavbar)}
            >
              <span className="navbar-toggler-icon"></span>
            </button>}

            <div className="navbar-home-content">
              <div>
                <div
                  class={`dropdown hover-underline-animation ${
                    selectedMenu === "games" && isMenuOpen ? "active" : ""
                  }`}
                >
                  <a
                    class={`nav-link dropdown-toggle ${
                      selectedMenu === "games" && isMenuOpen ? "active" : ""
                    }`}
                    // href="#"
                    // role="button"
                    // data-bs-toggle="dropdown"
                    // aria-expanded="false"
                    onClick={() => {
                      setIsMenuOpen(
                        isMenuOpen && selectedMenu !== "games"
                          ? true
                          : !isMenuOpen
                      );
                      setSelectedMenu("games");
                    }}
                  >
                    GAMES
                  </a>
                </div>
                <span className="divider "></span>
              </div>
              <div className="second-dropdown">
                {" "}
                <div className="dropdown">
                  <div
                    class={`dropdown hover-underline-animation ${
                      selectedMenu === "latest" && isMenuOpen ? "active" : ""
                    }`}
                  >
                    <a
                      class={`nav-link dropdown-toggle games ${
                        selectedMenu === "latest" && isMenuOpen ? "active" : ""
                      }`}
                      // href="#"
                      // role="button"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => {
                        setIsMenuOpen(
                          isMenuOpen && selectedMenu !== "latest"
                            ? true
                            : !isMenuOpen
                        );
                        setSelectedMenu("latest");
                      }}
                    >
                      LATEST
                    </a>
                  </div>
                </div>
                <div className="dropdown">
                  <div
                    class={`dropdown hover-underline-animation ${
                      selectedMenu === "visit" && isMenuOpen ? "active" : ""
                    }`}
                  >
                    <a
                      class={`nav-link dropdown-toggle games ${
                        selectedMenu === "visit" && isMenuOpen ? "active" : ""
                      }`}
                      // href="#"
                      // role="button"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => {
                        setIsMenuOpen(
                          isMenuOpen && selectedMenu !== "visit"
                            ? true
                            : !isMenuOpen
                        );
                        setSelectedMenu("visit");
                      }}
                    >
                      VISIT
                    </a>
                  </div>
                </div>
                <div className="" onClick={() => history.push("/sports")}>
                  <a
                    className="nav-link hover-underline-animation"
                    // aria-current="page"
                    aria-hidden="true"
                    onClick={() => {
                      setSelectedMenu("");
                    }}
                  >
                    SPORTS
                  </a>
                  <div className="link-hover"></div>
                </div>
                <div className="navbar-sponsers">
                  <Tooltip
                    title="GOVERNMENT OF GOA"
                    arrow
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    <img
                      loading="lazy"
                      src={goa}
                      alt="goa"
                      aria-label="goa"
                      className="navbar-logo"
                    />
                  </Tooltip>
                  <Tooltip title="THE SPORTS AUTHORITY OF GOA" arrow>
                    <img
                      loading="lazy"
                      src={sag}
                      alt="sag"
                      aria-label="sag"
                      className="navbar-logo"
                    />
                  </Tooltip>
                  <Tooltip
                    title="MINISTRY OF YOUTH AFFAIRS AND SPORTS GOVERNMENT OF INDIA"
                    arrow
                  >
                    <img
                      loading="lazy"
                      src={ministry}
                      alt="ministry"
                      aria-label="ministry"
                      className="navbar-logo"
                    />
                  </Tooltip>
                  <Tooltip title="INDIAN OLYMPIC ASSOCIATION" arrow>
                    <img
                      loading="lazy"
                      src={IOA}
                      alt="IOA"
                      aria-label="IOA"
                      className="navbar-logo"
                    />
                  </Tooltip>
                </div>
                <div className="" onClick={() => history.push("/teams")}>
                  <a
                    className="nav-link hover-underline-animation"
                    onClick={() => {
                      setSelectedMenu("");
                    }}
                  >
                    TEAMS
                  </a>
                  <div className="link-hover"></div>
                </div>
                <div className="dropdown">
                  <div
                    class={`dropdown hover-underline-animation ${
                      selectedMenu === "milestone" && isMenuOpen ? "active" : ""
                    }`}
                  >
                    <a
                      class={`nav-link dropdown-toggle games ${
                        selectedMenu === "milestone" && isMenuOpen
                          ? "active"
                          : ""
                      }`}
                      // href="#"
                      // role="button"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => {
                        setIsMenuOpen(
                          isMenuOpen && selectedMenu !== "milestone"
                            ? true
                            : !isMenuOpen
                        );
                        setSelectedMenu("milestone");
                      }}
                    >
                      MILESTONES
                    </a>
                  </div>
                </div>
                <div className="dropdown">
                  <div
                    class={`dropdown hover-underline-animation ${
                      selectedMenu === "abouts" && isMenuOpen ? "active" : ""
                    }`}
                  >
                    <a
                      class={`nav-link dropdown-toggle games ${
                        selectedMenu === "abouts" && isMenuOpen ? "active" : ""
                      }`}
                      // href="#"
                      // role="button"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => {
                        setIsMenuOpen(
                          isMenuOpen && selectedMenu !== "abouts"
                            ? true
                            : !isMenuOpen
                        );
                        setSelectedMenu("abouts");
                      }}
                    >
                      ABOUT
                    </a>
                  </div>
                </div>
              </div>
              <div>
                <span className="divider "></span>
                <span>
                  {/* <i className="fa fa-bell-o bellicon " aria-hidden="true"></i> */}
                  <div
                    onClick={() =>
                      window.open("https://tsr.37nationalgamesgoa.in")
                    }
                  >
                    <a
                      className="nav-link hover-underline-animation"
                      // aria-current="page"
                      aria-hidden="true"
                    >
                      TSR
                    </a>
                  </div>
                </span>
                <div className="ham-login">
                  <a
                    className="nav-link float-button"
                    onClick={() => {
                      window.location.href =
                        "http://gms.37nationalgamesgoa.in/";
                    }}
                  >
                    <span>LOGIN</span>
                    <div class="liquid"></div>
                  </a>
                </div>
              </div>
            </div>
            {openNavbar && (
              <div className={styles.navbar} ref={wrapperRef}>
                <div className={styles.navbarSponsor}>
                  <Tooltip
                    title="GOVERNMENT OF GOA"
                    arrow
                    style={{ color: "#000", backgroundColor: "#fff" }}
                  >
                    <img
                      loading="lazy"
                      src={goa}
                      alt="goa"
                      aria-label="goa"
                      className="navbar-logo"
                      aria-hidden="true"
                    />
                  </Tooltip>
                  <Tooltip title="THE SPORTS AUTHORITY OF GOA" arrow>
                    <img
                      loading="lazy"
                      src={sag}
                      alt="sag"
                      aria-label="sag"
                      className="navbar-logo"
                      aria-hidden="true"
                    />
                  </Tooltip>
                  <Tooltip
                    title="MINISTRY OF YOUTH AFFAIRS AND SPORTS GOVERNMENT OF INDIA"
                    arrow
                  >
                    <img
                      loading="lazy"
                      src={ministry}
                      alt="ministry"
                      aria-label="ministry"
                      className="navbar-logo"
                      aria-hidden="true"
                    />
                  </Tooltip>
                  <Tooltip title="INDIAN OLYMPIC ASSOCIATION" arrow>
                    <img
                      loading="lazy"
                      src={IOA}
                      alt="IOA"
                      aria-label="IOA"
                      className="navbar-logo"
                      aria-hidden="true"
                    />
                  </Tooltip>
                </div>
                <div>
                  <div
                    class={`dropdown hover-underline-animation ${
                      selectedMenu === "games" && isMenuOpen ? "active" : ""
                    }`}
                  >
                    <a
                      class={`nav-link dropdown-toggle games ${
                        selectedMenu === "games" && isMenuOpen ? "active" : ""
                      }`}
                      onClick={() => {
                        if (selectedMenu === "games") {
                          setSelectedMenu("");
                        } else {
                          setSelectedMenu("games");
                        }
                      }}
                    >
                      GAMES
                    </a>
                  </div>
                  <span className="divider "></span>
                </div>
                {selectedMenu === "games" && (
                  <ul class="dropdown-menu show games-drop" ref={wrapperRef}>
                    {subMenuList[0]?.list?.map((item, index) => {
                      return (
                        <li key={index}>
                          <a
                            class="dropdown-item nav-link hover-underline-animation"
                            onClick={() => {
                              history.push(`${item.path}`);
                              setSelectedMenu("");
                              setOpenNavbar(false);
                            }}
                          >
                            {item?.label}
                          </a>
                        </li>
                      );
                    })}
                  </ul>
                )}
                <div className="second-dropdown">
                  <div className="dropdown">
                    <div
                      class={`dropdown hover-underline-animation ${
                        selectedMenu === "latest" && isMenuOpen ? "active" : ""
                      }`}
                    >
                      <a
                        class={`nav-link dropdown-toggle games ${
                          selectedMenu === "latest" && isMenuOpen
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedMenu === "latest") {
                            setSelectedMenu("");
                          } else {
                            setSelectedMenu("latest");
                          }
                        }}
                      >
                        LATEST
                      </a>
                    </div>
                  </div>
                  {selectedMenu === "latest" && (
                    <ul class="dropdown-menu show games-drop" ref={wrapperRef}>
                      {subMenuList[1]?.list?.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              class="dropdown-item nav-link hover-underline-animation"
                              onClick={() => {
                                history.push(`${item.path}`);
                                setSelectedMenu("");
                                setOpenNavbar(false);
                              }}
                            >
                              {item?.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="dropdown">
                    <div
                      class={`dropdown hover-underline-animation ${
                        selectedMenu === "visit" && isMenuOpen ? "active" : ""
                      }`}
                    >
                      <a
                        class={`nav-link dropdown-toggle games ${
                          selectedMenu === "visit" && isMenuOpen ? "active" : ""
                        }`}
                        onClick={() => {
                          if (selectedMenu === "visit") {
                            setSelectedMenu("");
                          } else {
                            setSelectedMenu("visit");
                          }
                        }}
                      >
                        VISIT
                      </a>
                    </div>
                  </div>
                  {selectedMenu === "visit" && (
                    <ul class="dropdown-menu show games-drop" ref={wrapperRef}>
                      {subMenuList[2]?.list?.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              class="dropdown-item nav-link hover-underline-animation"
                              onClick={() => {
                                history.push(`${item.path}`);
                                setSelectedMenu("");
                                setOpenNavbar(false);
                              }}
                            >
                              {item?.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="">
                    <a
                      className="nav-link hover-underline-animation"
                      // aria-current="page"
                      aria-hidden='true'
                      onClick={() => {
                        setSelectedMenu("");
                        setOpenNavbar(false);
                        history.push("/sports");
                      }}
                    >
                      SPORTS
                    </a>
                    <div className="link-hover"></div>
                  </div>
                  <div className="" onClick={() => history.push("/teams")}>
                    <a
                      className="nav-link hover-underline-animation"
                      onClick={() => {
                        setOpenNavbar(false);
                        setSelectedMenu("");
                        history.push("/teams");
                      }}
                    >
                      TEAMS
                    </a>
                    <div className="link-hover"></div>
                  </div>
                  <div className="dropdown">
                    <div
                      class={`dropdown hover-underline-animation ${
                        selectedMenu === "milestone" && isMenuOpen
                          ? "active"
                          : ""
                      }`}
                    >
                      <a
                        class={`nav-link dropdown-toggle games ${
                          selectedMenu === "milestone" && isMenuOpen
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedMenu === "milestone") {
                            setSelectedMenu("");
                          } else {
                            setSelectedMenu("milestone");
                          }
                        }}
                      >
                        MILESTONES
                      </a>
                    </div>
                  </div>
                  {selectedMenu === "milestone" && (
                    <ul class="dropdown-menu show games-drop" ref={wrapperRef}>
                      {subMenuList[3]?.list?.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              class="dropdown-item nav-link hover-underline-animation"
                              onClick={() => {
                                history.push(`${item.path}`);
                                setSelectedMenu("");
                                setOpenNavbar(false);
                              }}
                            >
                              {item?.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                  <div className="dropdown">
                    <div
                      class={`dropdown hover-underline-animation ${
                        selectedMenu === "abouts" && isMenuOpen ? "active" : ""
                      }`}
                    >
                      <a
                        class={`nav-link dropdown-toggle games ${
                          selectedMenu === "abouts" && isMenuOpen
                            ? "active"
                            : ""
                        }`}
                        onClick={() => {
                          if (selectedMenu === "abouts") {
                            setSelectedMenu("");
                          } else {
                            setSelectedMenu("abouts");
                          }
                        }}
                      >
                        ABOUT
                      </a>
                    </div>
                  </div>
                  {selectedMenu === "abouts" && (
                    <ul class="dropdown-menu show games-drop" ref={wrapperRef}>
                      {subMenuList[4]?.list?.map((item, index) => {
                        return (
                          <li key={index}>
                            <a
                              class="dropdown-item nav-link hover-underline-animation"
                              onClick={() => {
                                history.push(`${item.path}`);
                                setSelectedMenu("");
                                setOpenNavbar(false);
                              }}
                            >
                              {item?.label}
                            </a>
                          </li>
                        );
                      })}
                    </ul>
                  )}
                </div>
                <div style={{ marginTop: 20 }}>
                  <span className="divider "></span>
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <i
                      className="fa fa-bell-o bellicon "
                      aria-hidden="true"
                      style={{ marginLeft: 0 }}
                    ></i>
                  </div>
                  <div
                    className="ham-login"
                    style={{ display: "flex", justifyContent: "center" }}
                  >
                    <a
                      className="nav-link float-button"
                      onClick={() => {
                        window.location.href =
                          "http://gms.37nationalgamesgoa.in/";
                      }}
                    >
                      <span>LOGIN</span>
                      <div class="liquid"></div>
                    </a>
                  </div>
                </div>
              </div>
            )}
          </div>
        </nav>
        {selectedSubMenuList && isMenuOpen && window.screen.width >= 1000 && (
          <ul class="dropdown-menu show games-drop" ref={wrapperRef}>
            {selectedSubMenuList?.map((item, index) => {
              return (
                <li key={index}>
                  <a
                    class="dropdown-item nav-link hover-underline-animation"
                    onClick={() => {
                      history.push(`${item.path}`);
                      setIsMenuOpen(false);
                    }}
                  >
                    {item?.label}
                  </a>
                </li>
              );
            })}
          </ul>
        )}
        <NavLogin />
      </div>
    </>
  );
};

export default Navbar;
