// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.navbar_navbar__eiNcb {
  z-index: 1000;
  top: 65px;
  background-color: #8968d6;
  width: 100vw;
  text-align: center;
}
.navbar_navbarSponsor__Vfqu4 {
  height: 80px;
  display: flex;
  align-items: center;
  background: #ffff;
  justify-content: space-evenly;
  padding: 0 40px;
  /* clip-path: polygon(0 0%, 100% 0, 86% 100%, 13% 100%); */
  cursor: pointer;
  width: 100%;
}
`, "",{"version":3,"sources":["webpack://./src/components/navbar/navbar.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,SAAS;EACT,yBAAyB;EACzB,YAAY;EACZ,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,aAAa;EACb,mBAAmB;EACnB,iBAAiB;EACjB,6BAA6B;EAC7B,eAAe;EACf,0DAA0D;EAC1D,eAAe;EACf,WAAW;AACb","sourcesContent":[".navbar {\n  z-index: 1000;\n  top: 65px;\n  background-color: #8968d6;\n  width: 100vw;\n  text-align: center;\n}\n.navbarSponsor {\n  height: 80px;\n  display: flex;\n  align-items: center;\n  background: #ffff;\n  justify-content: space-evenly;\n  padding: 0 40px;\n  /* clip-path: polygon(0 0%, 100% 0, 86% 100%, 13% 100%); */\n  cursor: pointer;\n  width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"navbar": `navbar_navbar__eiNcb`,
	"navbarSponsor": `navbar_navbarSponsor__Vfqu4`
};
export default ___CSS_LOADER_EXPORT___;
