import { createSlice } from "@reduxjs/toolkit";
import { fetchPlayersList } from "api/playersApiService";

const initialState = {
  playersList: null,
};

const playersSlice = createSlice({
  name: "playersReducer",
  initialState,
  reducers: {
    setPlayersList: (state, action) => {
      state.playersList = action.payload;
    },
  },
});

export const { setPlayersList } = playersSlice.actions;

export const getPlayersList = () => {
  return async (dispatch) => {
    try {
      const response = await fetchPlayersList();
      if (response?.status === 200) {
        dispatch(setPlayersList(response?.data?.player_details));
      } else {
        console.log("Something went wrong");
      }
    } catch (error) {
      console.error(error);
    }
  };
};

export default playersSlice.reducer;
