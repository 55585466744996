export const sportToImgIcons = {
  aquatics: "/WhiteIcons/Aquatics.png",
  archery: "/WhiteIcons/Archery.png",
  athletics: "/WhiteIcons/Athletics.png",
  badminton: "/WhiteIcons/badminton.png",
  basketball: "/WhiteIcons/Basketball.png",
  "billiard-&-snooker": "/WhiteIcons/billiard-and-snooker.png",
  boxing: "/WhiteIcons/boxing.png",
  "canoeing-&-kayaking": "/WhiteIcons/Canoeing-_-Kayaking.png",
  canoeing: "/WhiteIcons/Canoeing-_-Kayaking.png",
  cycling: "/WhiteIcons/cycling.png",
  fencing: "/WhiteIcons/fencing.png",
  football: "/WhiteIcons/football.png",
  "beach-football": "/WhiteIcons/football.png",
  gatka: "/WhiteIcons/gatka.png",
  golf: "/WhiteIcons/golf.png",
  "mini-golf": "/WhiteIcons/golf.png",
  gymnastics: "/WhiteIcons/gymnastic.png",
  "beach-handball": "/WhiteIcons/handball-and-beach-handball.png",
  handball: "/WhiteIcons/handball-and-beach-handball.png",
  hockey: "/WhiteIcons/hockey.png",
  judo: "/WhiteIcons/judo.png",
  kabaddi: "/WhiteIcons/kabaddi.png",
  kalaripayattu: "/WhiteIcons/kallaripayattu.png",
  "kho-kho": "/WhiteIcons/kho-kho.png",
  lagori: "/WhiteIcons/lagori.png",
  "lawn-bowl": "/WhiteIcons/lawn-bowl.png",
  "lawn-ball": "/WhiteIcons/lawn-bowl.png",
  "lawn-balls": "/WhiteIcons/lawn-bowl.png",
  mallakhamb: "/WhiteIcons/mallakhamb.png",

  malakhamb: "/WhiteIcons/mallakhamb.png",
  "modern-pentathlon": "/WhiteIcons/Modern-Pentathlon.png",
  "modern-pentathlon-": "/WhiteIcons/Modern-Pentathlon.png",
  "modern pentathlon": "/WhiteIcons/Modern-Pentathlon.png",
  "modern pentathlon ": "/WhiteIcons/Modern-Pentathlon.png",
  "modern-panthalon": "/WhiteIcons/Modern-Pentathlon.png",
  netball: "/WhiteIcons/netball.png",
  "pencak-silat": "/WhiteIcons/Pencak-Silat.png",
  "roll-ball": "/WhiteIcons/roll-ball.png",
  rollball: "/WhiteIcons/roll-ball.png",
  rowing: "/WhiteIcons/rowing.png",
  rugby: "/WhiteIcons/Rugby.png",
  "sepak-takraw": "/WhiteIcons/sepak-takraw.png",
  shooting: "/WhiteIcons/shooting.png",
  "sqay-martial-art": "/WhiteIcons/sqay-martial-art.png",
  squash: "/WhiteIcons/squash.png",
  "table-tennis": "/WhiteIcons/table-tennis.png",
  taekwondo: "/WhiteIcons/taekwondo.png",
  tennis: "/WhiteIcons/tennis.png",
  "lawn-tennis": "/WhiteIcons/tennis.png",
  triathlon: "/WhiteIcons/Trithlon.png",
  "beach-volleyball": "/WhiteIcons/Volleyball-_-Beach-Volleybal.png",
  volleyball: "/WhiteIcons/Volleyball-_-Beach-Volleybal.png",
  weightlifting: "/WhiteIcons/weightlifting.png",
  wrestling: "/WhiteIcons/wrestling.png",
  wushu: "/WhiteIcons/wushu.png",
  yachting: "/WhiteIcons/yachting.png",
  yogasana: "/WhiteIcons/yoga.png",
};

export const venueDates = {
  Delhi: [
    {
      sports: "Cycling (Track Race)",
      city: "Delhi",
      venu: "Indira Gandhi Stadium Complex, New Delhi",
      sdate: "2 Nov 2023",
      edate: "5 Nov 2023",
      routing: "cycling",
    },
    {
      sports: "Golf",
      city: "Delhi",
      venu: "Delhi Golf Club",
      sdate: "5 Nov 2023",
      edate: "9 Nov 2023",
      routing: "golf",
    },
  ],
  Mapusa: [
    {
      sports: "Gymnastics",
      city: "Mapusa",
      venu: "Peddem Multi-Purpose Indoor Stadium",
      sdate: "23 Oct 2023",
      edate: "28 Oct 2023",
      routing: "gymnasium",
    },
    {
      sports: "Billiards & Snookers",
      city: "Mapusa ",
      venu: "Badminton Hall, Peddem Sports Complex",
      sdate: "27 Oct 2023",
      edate: "30 Oct 2023",
      key: "billiard-&-snooker",
      routing: "billiard-&-snooker",
    },

    {
      sports: "Rowing",
      city: "Mapusa ",
      venu: "Chapora River ",
      sdate: "28 Oct 2023",
      edate: "1 Nov 2023",
      routing: "rowing",
    },
    {
      sports: "Canoeing & Kayaking",
      city: "Mapusa ",
      venu: "Chapora River ",
      sdate: "4 Nov 2023",
      edate: "7 Nov 2023",
      key: "canoeing-&-kayaking",
      routing: "canoeing-&-kayaking",
    },
    {
      sports: "Hockey",
      city: "Mapusa ",
      venu: "Hockey Ground, Peddem Sports Complex",
      sdate: "30 Oct 2023",
      edate: "8 Nov 2023",
      routing: "hockey",
    },
    {
      sports: "Boxing",
      city: "Mapusa ",
      venu: "Peddem Multi-Purpose Indoor Stadium",
      sdate: "1 Nov 2023",
      edate: "8 Nov 2023",
      routing: "boxing",
    },
    {
      sports: "Shooting",
      city: "Mapusa",
      venu: "Mendrem Shooting Range",
      sdate: "2 Nov 2023",
      edate: "9 Nov 2023",
      routing: "shooting",
    },
  ],
  Margao: [
    {
      sports: "Basketball",
      city: "Margao",
      venu: " Manohar Parrikar Indoor Stadium, Navelim",
      sdate: "23 Oct 2023",
      edate: "28 Oct 2023",
      routing: "basketball",
    },
    {
      sports: "Football (Men)",
      city: "Margao",
      venu: "PJN Stadium, Fatorda",
      sdate: "30 Oct 2023",
      edate: "8 Nov 2023",
      routing: "football",
    },
    {
      sports: "Rollball",
      city: "Margao",
      venu: " Manohar Parrikar Indoor Stadium, Navelim",
      sdate: "30 Oct 2023",
      edate: "2 Nov 2023",
      routing: "rollball",
    },
    {
      sports: "Sepak Takraw",
      city: "Margao",
      venu: "Fatorda Multi-Purpose Hall",
      sdate: "30 Oct 2023",
      edate: "3 Nov 2023",
      key: "sepak-takraw",
      routing: "sepak-takraw",
    },
    {
      sports: "Lawn Tennis",
      city: "Margao",
      venu: "Multi-Purpose Ground, Fatorda",
      sdate: "30 Oct 2023",
      edate: "5 Nov 2023",
      routing: "lawn-tennis",
    },
    {
      sports: "Beach Football",
      city: "Margao",
      venu: "Colva Beach",
      sdate: "28 Oct 2023",
      edate: "1 Nov 2023",
      routing: "beach-football",
    },
    {
      sports: "Handball",
      city: "Margao",
      venu: " Manohar Parrikar Indoor Stadium, Navelim",
      sdate: "4 Nov 2023",
      edate: "8 Nov 2023",
      routing: "handball",
    },
    {
      sports: "Sqay Martial Arts",
      city: "Margao",
      venu: "Fatorda Multi-Purpose Hall",
      sdate: "6 Nov 2023",
      edate: "8 Nov 2023",
      key: "sqay-martial-arts",
      routing: "sqay-martial-arts",
    },
  ],
  Panjim: [
    {
      sports: "Mini Golf",
      city: "Panjim",
      venu: "Miramar Beach",
      sdate: "1 Nov 2023",
      edate: "3 Nov 2023",
      routing: "mini-golf",
    },
    {
      sports: "Badminton",
      city: "Panjim",
      venu: "S.P.M Stadium, Goa University",
      sdate: "19 Oct 2023",
      edate: "24 Oct 2023",
      routing: "badminton",
    },
    {
      sports: "Netball",
      city: "Panjim",
      venu: "Campal Multi-Purpose Indoor Stadium",
      sdate: "22 Oct 2023",
      edate: "27 Oct 2023",
      routing: "netball",
    },
    {
      sports: "Weightlifting",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "25 Oct 2023",
      edate: "29 Oct 2023",
      routing: "weightlifting",
    },
    {
      sports: "Pencak Silat",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "26 Oct 2023",
      edate: "29 Oct 2023",
      key: "pencak-silat",
      routing: "pencak-silat",
    },
    {
      sports: "Mallakhamb",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "26 Oct 2023",
      edate: "28 Oct 2023",
      routing: "mallakhamb",
    },
    {
      sports: "Fencing",
      city: "Panjim",
      venu: "S.P.M Stadium, Goa University",
      sdate: "26 Oct 2023",
      edate: "30 Oct 2023",
      routing: "fencing",
    },
    {
      sports: "Rugby",
      city: "Panjim",
      venu: "Athletics Stadium, Bambolim",
      sdate: "25 Oct 2023",
      edate: "27 Oct 2023",
      routing: "rugby",
    },
    {
      sports: "Beach Handball",
      city: "Panjim",
      venu: "Miramar Beach, Varca Beach",
      sdate: "28 Oct 2023",
      edate: "1 Nov 2023",
      routing: "beach-handball",
    },
    {
      sports: "Beach Volleyball",
      city: "Panjim",
      venu: "Miramar Beach, Varca Beach",
      sdate: "4 Nov 2023",
      edate: "7 Nov 2023",
      routing: "beach-volleyball",
    },
    {
      sports: "Kabaddi",
      city: "Panjim",
      venu: "Campal Multi-Purpose Indoor Stadium",
      sdate: "4 Nov 2023",
      edate: "8 Nov 2023",
      routing: "kabaddi",
    },
    {
      sports: "Wrestling",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "1 Nov 2023",
      edate: "3 Nov 2023",
      routing: "wrestling",
    },
    {
      sports: "Aquatics",
      city: "Panjim",
      venu: "Swimming Pool Campal",
      sdate: "29 Oct 2023",
      edate: "4 Nov 2023",
      routing: "aquatics",
    },
    {
      sports: "Gatka",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "31 Oct 2023",
      edate: "2 Nov 2023",
      routing: "gatka",
    },
    {
      sports: "Athletics",
      city: "Panjim",
      venu: "Athletics Stadium, Bambolim",
      sdate: "29 Oct 2023",
      edate: "3 Nov 2023",
      routing: "athletics",
    },
    {
      sports: "Wushu",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "1 Nov 2023",
      edate: "4 Nov 2023",
      routing: "wushu",
    },
    {
      sports: "Rowing (Coastal/Tidal Sport)",
      city: "Panjim",
      venu: "Hawai Beach, Dona Paula",
      sdate: "3 Nov 2023",
      edate: "6 Nov 2023",
      routing: "rowing",
    },
    {
      sports: "Volleyball",
      city: "Panjim",
      venu: "S.P.M Stadium, Goa University",
      sdate: "1 Nov 2023",
      edate: "5 Nov 2023",
      routing: "volleyball",
    },

    {
      sports: "Triathlon",
      city: "Panjim",
      venu: "Caranzalem - Miramar Road",
      sdate: "4 Nov 2023",
      edate: "7 Nov 2023",
      routing: "triathlon",
    },
    {
      sports: "Yachting",
      city: "Panjim",
      venu: "Hawaii Beach, Dona Paula",
      sdate: "3 Nov 2023",
      edate: "8 Nov 2023",
      routing: "yachting",
    },
    {
      sports: "Table Tennis",
      city: "Panjim",
      venu: "Campal Multi-Purpose Indoor Stadium",
      sdate: "29 Oct 2023",
      edate: "2 Nov 2023",
      routing: "table-tennis",
    },

    {
      sports: "Lagori",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "5 Nov 2023",
      edate: "6 Nov 2023",
      routing: "lagori",
    },
    {
      sports: "Yogasana",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "5 Nov 2023",
      edate: "9 Nov 2023",
      routing: "yogasana",
    },
    {
      sports: "Judo",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "6 Nov 2023",
      edate: "8 Nov 2023",
      routing: "judo",
    },
    {
      sports: "Kalaripayattu",
      city: "Panjim",
      venu: "Campal Open Ground (Campal Sports Village)",
      sdate: "7 Nov 2023",
      edate: "8 Nov 2023",
      routing: "kalaripayattu",
    },
  ],
  Ponda: [
    {
      sports: "Modern Pentathlon",
      city: "Ponda",
      venu: "Ponda Swimming Pool - Indoor - Ground",
      sdate: "26 Oct 2023",
      edate: "29 Oct 2023",
      routing: "modern-pentathlon",
    },
    {
      sports: "Archery",
      city: "Ponda",
      venu: "Goa Engineering Collage, Farmagudi - Ponda",
      sdate: "29 Oct 2023",
      edate: "6 Nov 2023",
      routing: "archery",
    },
    {
      sports: "Taekwondo",
      city: "Ponda",
      venu: "Ponda Multi-Purpose Indoor Stadium",
      sdate: "31 Oct 2023",
      edate: "3 Nov 2023",
      routing: "taekwondo",
    },
    {
      sports: "Kho-Kho",
      city: "Ponda",
      venu: "Ponda Multi-Purpose Indoor Stadium",
      sdate: "4 Nov 2023",
      edate: "8 Nov 2023",
      routing: "kho-kho",
    },
  ],
  Vasco: [
    {
      sports: "Football (Women)",
      city: "Vasco",
      venu: "Tilak Maidan Football Ground",
      sdate: "27 Oct 2023",
      edate: "5 Nov 2023",
      routing: "football",
    },
    {
      sports: "Lawn Bowls",
      city: "Vasco",
      venu: "Chicalim Multi-Purpose Ground",
      sdate: "1 Nov 2023",
      edate: "8 Nov 2023",
      key: "lawn-bowl",
      routing: "lawn-bowl",
    },
    {
      sports: "Squash",
      city: "Vasco",
      venu: "Chicalim Squash Facility",
      sdate: "1 Nov 2023",
      edate: "5 Nov 2023",
      routing: "squash",
    },
    {
      sports: "Cycling (Road Race)",
      city: "Vasco",
      venu: "Verna - Birla Bypass Airport Road",
      sdate: "8 Nov 2023",
      edate: "9 Nov 2023",
      routing: "cycling",
    },
  ],
};
