import { useHistory } from "react-router-dom";

const NavLogo = ({ click }) => {
  const history = useHistory();
  return (
    <div
      style={{ zIndex: 11 }}
      className="navbar-brands cursor-pointer"
      onClick={() => {
        click();
        history.push("/");
      }}
    >
      <div>
        <img
          loading="lazy"
          src="/logo/National Games White Logo.png"
          alt="National Games White Logo"
          className="navlogo"
        />
      </div>
    </div>
  );
};

export default NavLogo;
