// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.error-page {
  text-align: center;
}

.oops {
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 10px;
}

.error-message {
  font-size: 40px;
}

.retry-button {
  font-size: 18px;
  padding: 5px;
  border-radius: 10px;
  margin-top: 20px;
}
`, "",{"version":3,"sources":["webpack://./src/components/ErrorBoundary/ErrorPage.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,iBAAiB;EACjB,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,eAAe;EACf,YAAY;EACZ,mBAAmB;EACnB,gBAAgB;AAClB","sourcesContent":[".error-page {\n  text-align: center;\n}\n\n.oops {\n  font-size: 70px;\n  font-weight: bold;\n  margin-bottom: 10px;\n}\n\n.error-message {\n  font-size: 40px;\n}\n\n.retry-button {\n  font-size: 18px;\n  padding: 5px;\n  border-radius: 10px;\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
