import swetika from "assets/notes/SwetikaSachan.png";
import amitabh from "assets/notes/AmitabhSharma.png";
import usha from "assets/notes/PTUSHA.png";
import govind from "assets/notes/govind.png";
import sawant from "assets/notes/Sawant.png";
import geetha from "assets/notes/geetha.png";
import Pramanik from "assets/notes/Pramanik.png";
import bg2 from "assets/notes/bg2.svg";
import bg6 from "assets/notes/bg6.svg";
import bg5 from "assets/notes/bg5.svg";
import bg4 from "assets/notes/bg4.svg";
import bg3 from "assets/notes/bg3.svg";
import bg1 from "assets/notes/bg1.svg";
import goa from "assets/gamePartners/goa.png";
import IOA from "assets/gamePartners/ioa.png";
import ministry from "assets/gamePartners/ministry.png";
import sag from "assets/gamePartners/sag.png";
import COSCO from "assets/gamePartners/COSCO.png";
import FITSUPPORT from "assets/gamePartners/FITSUPPORT.png";
import IndianOil from "assets/gamePartners/Indian Oil.png";
import ONGC from "assets/gamePartners/ONGC.png";
import Paytm from "assets/gamePartners/Paytm.png";
import Powergrid from "assets/gamePartners/Powergrid.png";
import Spartan from "assets/gamePartners/Spartan.png";
import T10sports from "assets/gamePartners/T10sports.png";
import CoalIndia from "assets/gamePartners/Coal_India_Logo.png";
import NLC from "assets/gamePartners/nlc.png";
import PFC from "assets/gamePartners/PFC.png";
import SAIL from "assets/gamePartners/SAIL.png";
import Bisleri from "assets/gamePartners/Bisleri.jpeg";
import jiocinema from "assets/gamePartners/jiocinema.png";
import DD_Sports from "assets/gamePartners/DD_Sports.jpg";

export const parseAxiosError = (error) => {
  if (error?.isAxiosError && error?.response) {
    return {
      status: "success",
      message: error?.response?.data?.message,
      error,
    };
  }

  return { status: "failure", message: error?.message, error };
};

export const questionAnswer = [
  {
    section: `Registration, Documents Verification and Nomination`,
    questions: [
      {
        question: "What is the process of registration?",
        answer:
          "All the National Sports Federation will be doing their respective Athlete Registration",
      },
      {
        question: "Which documents are necessary for athlete registration?",
        answer:
          "Aadhar Card or Passport and Passport Size photo of Athlete are required for Athlete registration",
      },
      {
        question: "Do you need to bring documents to the stadium?",
        answer:
          "No, documents are only required for the process of document verification and to get your accreditation card. You only need to get your accreditation card to get access to the venue.",
      },
      {
        question:
          "Can an athlete be disqualified from a sports event after registration and nomination?",
        answer: `Yes, an athlete can be disqualified if he/she is: not complying to the rules and regulations of the sport or misbehaving during the event.
          `,
      },
      {
        question: "Where can I find the Document verification Status?",
        answer:
          "You can find your Verification details on your Dashboard after logging in.",
      },
    ],
  },
  {
    section: `Login Process`,
    questions: [
      {
        question: "How to log in to my Dashboard? ",
        answer: `<p>Visit the below-mentioned link <a class="inner-link" href="https://gms.37nationalgamesgoa.in/userlogin" target="_blank">https://gms.37nationalgamesgoa.in/userlogin</a> and enter your registered Email ID and Password.</p>`,
      },
      {
        question: "Where can I log in from?",
        answer: `<p>To log in visit the below-mentioned link 
        <a class="inner-link" href="https://gms.37nationalgamesgoa.in/userlogin" target="_blank">https://gms.37nationalgamesgoa.in/userlogin</a></p>`,
      },
      {
        question: "Where can I find my login details?",
        answer: `<div>You will be receiving your registered Email ID and Password through SMS on your registered mobile no. for logging in your dashboard.
        <br/>
<p>In case you haven’t received your credentials kindly contact our helpline: 08061115103</p>
        </div>`,
      },
      {
        question: " Why one should login on their dashboard?",
        answer: `<span>You should login on your dashboard in order to view your specific details regarding their 
        <span>Sports</span></br>
        <span>Schedule</span></br>
        <span>Document Verification Status</span></br>
        <span>Accreditation Status</span></br>
        <span>Accommodation, Catering and Travel Details</span></br>
        <span>For more doubts regarding these please contact: 08061115103 </span></br>       
        </span>`,
      },
    ],
  },
  {
    section: `Accreditation`,
    questions: [
      {
        question: "How do I collect my accreditation?",
        answer: `<p>These are the main centers where you / DOC can collect your accreditation from</br>
       <p>MAC - S.P.M Stadium, Goa University</p>
       <p>CAC - Ponda Multi-purpose Indoor Stadium</p>
       <p>
       Chicalim Multi-Purpose Ground, Chicalim Squash Facility
</p>
       <p>
       PJN Stadium Fatorda, Fatorda Stadium, Multi-Purpose Hall</p>
       <p>
       Peddem Sports Complex (Hockey Ground/Indoor Hall/Multi-Purpose Hall)
</p>
       <p>
       Campal Multi-Purpose Indoor Stadium, Campal Open Ground, Swimming Pool
</p></br>
<p>Apart from these venues, other venues will also have Venue Accreditation Center. </p>
        </p>`,
      },
      {
        question: "What should the next step be if I lose my accreditation?",
        answer: `If you lose your accreditation card you need to contact Main Accreditation Center (MAC).`,
      },
      {
        question: "Can anyone other than me collect my accreditation?",
        answer: `Only DOC can access the main accreditation department, hence only DOC can collect the accreditation.`,
      },
      {
        question:
          "How can media personnel obtain accreditation for covering the event?",
        answer: `Media personnel can fill out the form, on the link available on the website & collect it from the Main Accreditation Center.`,
      },
    ],
  },
  {
    section: `Accommodation, Travelling and Catering`,
    questions: [
      {
        question: `How can I get my Accommodation, Catering, and Travel details?`,
        answer: `You can find your details about your accommodation, catering, and traveling on your dashboard after logging in.`,
      },
      {
        question:
          "How to get my Accommodation (Hotel) details at the time of Arrival?",
        answer: `<p>Our Team is deployed at various arrival points like Airports, Railway Stations, Bus Stands and State Borders. As per your arrival kindly contact our team at the Venue, they will provide you with transportation to your respective Hotels. Kindly find the contact details of team as per your venue in the below pdf:
        <a
        class="inner-link"
        href="/assets/pdf/ACT_Contact_Details_File.pdf"
        target="_blank">
        ACT Contact Details File
        </a></p>`,
        isLink: true,
      },
      // {
      //   question: "Where is my hotel located?",
      //   answer: "Where is my hotel located",
      // },
      // {
      //   question: "How is my in-city travel being planned?",
      //   answer: "How is my in-city travel being planned",
      // },
      {
        question: "Can I get the same vehicle to go around the city?",
        answer: "No, there will not be a car provided for personal use.",
      },
      // {
      //   question: " What are the vehicles that have been allocated for travel?",
      //   answer: "What are the vehicles that have been allocated for travel",
      // },
      // {
      //   question: "How would i know where are my meals being provided",
      //   answer: "How would i know where are my meals being provided?",
      // },
      // {
      //   question:
      //     "Will I be getting my breakfast at the stadium or at the hotel?",
      //   answer: "Will I be getting my breakfast at the stadium or at the hotel",
      // },
      // {
      //   question: "Where can I find my ACT Details?",
      //   answer: "For more doubts regarding these please contact: 08061115103",
      // },
    ],
  },
  {
    section: `Explore Goa`,
    questions: [
      {
        question: `Goa`,
        answer: `<p>On India's southwest coast is the state of Goa. It is one of the most wellliked tourist sites in the naƟon and is renowned for its stunning beaches,
        vibrant culture, and extensive history.
        <a
        class="inner-link"
        href="https://goa-tourism.com" 
        target="_blank">
        https://goa-tourism.com
        </a></br></p></p>`,
      },
    ],
  },
  {
    section: `Contact us`,
    questions: [
      {
        question: `Administration & Evaluation Committee`,
        answer: `Neekita Naik  - 9767791499`,
      },
      {
        question: `Evaluation (Finance) & Procurement / 
        E Procurement`,
        answer: `Umang Gosabi - 7030866475`,
      },
      {
        question: `Medical & Anti doping`,
        answer: `Jainab Tashneem - 9937241310`,
      },
      {
        question: `Marketing, Media & Host City Activation Committee`,
        answer: `Smit M. Phongde - 8806164611`,
      },
      {
        question: `ACT Committee`,
        answer: `<div>
                  <p>Manish Naik -  7517775829</p>
                  <p>Ashwini Patil - 7798288160</p>
                  <p>Ashwini Patil - 7798288160</p>
                 </div>`,
      },
      {
        question: `Accreditation, IT Management  ICT committee  & Control room`,
        answer: `Indraneel Waradkar - 9168170554`,
      },
      {
        question: `Games Conduct & Venue Readiness Committee`,
        answer: `Varun Bhardwaj - 7906297885`,
      },
      {
        question: `Volunteers Committee`,
        answer: `Al Sahaf Kabeer - 9497063919`,
      },
      {
        question: `Sports Infrastructure & Venue Overlays Committee`,
        answer: `Ameya Pradhan - 9029425296`,
      },
      {
        question: `State Coordination Committee`,
        answer: `Prasad Gaude - 8830539835`,
      },
    ],
  },
];

export const chatbotOptions = [
  `Registration, Documents Verification and Nomination`,
  `Login Process`,
  `Accreditation`,
  `Accommodation, Travelling and Catering`,
  `Explore Goa`,
  `Contact us`,
];

export const decisionMaking = ["Yes", "No"];

export const deskNotesContents = [
  {
    route: "Nitish",
    image: Pramanik,
    background: bg2,
    reverse: true,
    border: "#61A2F5",
    header: {
      name: "Shri Nitish Pramanik ",
      position: "Ministry of Home Affairs",
      thirdHeader: "Ministry of Youth Affairs and Sports",
    },
    footer: {
      great: "With warm regards,",
      name: "Shri Nitish Pramanik ",
      position: "Ministry of Home Affairs",
      thirdHeader: "Ministry of Youth Affairs and Sports",
    },
    contents: [
      `Greetings to all!`,
      `With utmost honour and privilege, I extend my greetings on the occasion of the 37th National Games in Goa. The National Games is a coming together of the immense sporting talent that our country beholds, and it displays the spirit of sportsmanship across the nation.`,
      `The vibrant state of Goa is all geared up with world-class facilities to welcome athletes from all across the nation. From state-of-the-art training facilities to comfortable accommodations, everything is set to ensure peak performance. Rest assured, our athletes can focus on their game, knowing every detail has been addressed.`,
      `To all our athletes: Goa offers more than just magnificent venues. It’s a warm embrace, ensuring you're well taken care of, and allowing you to shine in your respective fields.`,
      `The 37th National Games isn't just about competition. It's a reflection of our dedication and commitment to excellence. I have every confidence that our athletes will display unmatched skill, determination, and spirit.`,
      `Goa awaits, ready to host this grand spectacle. Brace yourselves for an unparalleled sporting journey amidst sun, sand, and true sportsmanship.`,
      `Thank you!`,
    ],
  },
  {
    route: "Pramod",
    image: sawant,
    background: bg2,
    reverse: true,
    border: "#61A2F5",
    header: {
      name: "Dr.Pramod Sawant",
      position: "Honourable Chief Minister of Goa",
    },
    footer: {
      great: "With warm regards,",
      name: "Dr.Pramod Sawant",
      position: "Honourable Chief Minister of Goa",
      thirdHeader: "Government of Goa",
    },
    contents: [
      `I am thrilled to have the 37th National Games 2023 happening at Goa. It is a monumental event that has been made possible through the collective efforts of all stakeholders within the Government of Goa, as well as the invaluable support from the Ministry of Youth Affairs and Sports and the Indian Olympic Association. This grand vision of organising the National Games has become a reality thanks to the painstaking efforts of the National Games Organising Committee. Their meticulous planning and holistic management of every aspect will undoubtedly contribute to the resounding success of the 37th National Games Goa 2023. Beyond being a sporting extravaganza, we envision the National Games Goa 2023 as an opportunity to showcase the vibrant culture and diverse traditions of Goa.`,
      ` It marks a significant step forward, moving beyond spiritual and medical tourism, positioning Goa as a hub for hosting high-level sports events in the future. The 37th National Games will witness the largest-ever participation of over 10,000 athletes from 28 states and 8 Union Territories, making it a truly national celebration of sports. Moreover, this edition introduces Coastal and Tidal Rowing for the first time in the history of National Games, reflecting our commitment to innovation in sports. We cannot overemphasise the importance of sports in fostering a healthy lifestyle and building an inclusive community.`,
      `It plays a pivotal role in instilling a spirit of competition and unity amongst us all. To host the National Games successfully, we've made substantial investments in infrastructure development, including new stadiums, training facilities, and upgrades. Local athletes will also receive robust support in terms of coaching and equipment. The resilient efforts of the National Games Organising Committee and the Sports Authority of Goa (SAG) have galvanised our entire state to welcome athletes from all corners of India. This prestigious event promises to boost our state's economy by promoting local businesses and tourism. We believe that the National Games Goa 2023 will leave a lasting legacy, showcasing Goa as a sports destination. Sports have a unique ability to unite our nation and foster a sense of pride. The transformational journey of sports in India has been significantly influenced by programs and initiatives launched by our Hon'ble Prime Minister, Shri Narendra Modi, such as Khelo India and Fit India. These initiatives have ignited a passion for sports across the country. We invite all of you to witness the live events and extend your support to the athletes who will represent not just their states but also the spirit of our great nation. For the first time in its history, Goa is poised to host the largest-ever National Games, featuring an impressive array of 43 sporting disciplines, including 5 Indigenous Games - Mallakhamb, Gatka, Lagori, Yogasana, and Kalaripayattu. This prestigious event promises to be a spectacular showcase of athletic excellence, camaraderie, and a platform for the debut of several exciting sports. Let us all come together and make the 37th National Games Goa 2023 an unforgettable celebration of sports, culture, and unity.`,
    ],
  },
  {
    route: "Govind",
    image: govind,
    background: bg3,
    reverse: false,
    border: "#A0D8A3",
    header: {
      name: "Shri Govind Gaude ",
      position: "Sports Minister Government of Goa",
    },
    footer: {
      great: "With warm regards,",
      name: "Shri Govind Gaude ",
      position: "Sports Minister Government of Goa",
    },
    contents: [
      `Dear Fellow Citizens,`,
      `Moye Mogacho Namaskar`,
      `Get ready to witness history unfolding as Goa proudly hosts the 37th National Games Goa 2023, a sporting spectacle that promises to be a game-changer for our beloved state!`,
      `This isn't just about sports; it's a celebration of Goa's spirit, diversity, and the youth. The whole nation is coming together in Goa's embrace, and we invite every citizen to be a part of this extraordinary journey.`,
      `Imagine over 10,000 athletes from 28 states and 8 Union Territories converging in Goa, all competing at the highest level of excellence. It's an unprecedented gathering of talent and a symbol of national unity.`,
      ` Goa is not just hosting a sports event; it's laying the foundation for a stronger sports ecosystem. Our investments in stadiums, training facilities, and upgrades will leave an enduring legacy, benefiting generations to come.`,
      ` I am also excited that our local athletes will thrive with enhanced coaching and access to these first-class facilities. This isn't just about the Games; it's about nurturing and supporting our own talent.`,
      `Lastly, Sports have the power to unite a nation, and Goa will be at the centre of this unity. Together, we'll celebrate the spirit of competition and take pride in our role as hosts.`,
      ` So, mark your calendars, spread the word, and join us in making history. Let's make the 37th National Games Goa 2023 a momentous event that defines our legacy and leaves a lasting impact. Its time to #GetSetGoa!`,
    ],
  },
  {
    route: "Usha",
    image: usha,
    background: bg4,
    reverse: true,
    border: "#E0C9CF",
    header: {
      name: "Smt P.T. Usha",
      position: "President of the Indian Olympic Association",
    },
    footer: {
      great: "With warm regards,",
      name: "Smt P.T. Usha",
      position: "President of the Indian Olympic Association",
    },
    contents: [
      `From the Desk of the President of the Indian Olympic Association Smt P.T. Usha`,
      `Greetings to all!`,
      `I'm deeply honoured to address you today, as we prepare for the 37th National Games in the scenic Goa. As the President of the Indian Olympic Association and a former athlete, I understand the profound impact of events like the National Games on shaping Indian sports.`,
      `Sports inspire, unite, and transform. I've had the honour of representing India on global platforms, so I can attest to the invaluable opportunities the National Games offer. Here, our athletes don't just compete; they grow, connect, and transcend boundaries.`,
      `The National Games equip our athletes for the world stage, offering exposure and experience that set the foundation for international success. Every challenge faced, bond formed, and lesson learned here propels them forward.`,
      `To the athletes of the 37th National Games: seize this moment. You represent the dreams and hopes of millions. Your endeavours will not only mark this event but will inspire countless future champions.`,
      `Thank you!`,
    ],
  },
  {
    route: "Amitabh",
    image: amitabh,
    background: bg5,
    reverse: true,
    border: "#DBDEE5",
    header: {
      name: "Shri Amitabh Sharma",
      position: "Chairman of the National Games Technical Conduct",
      thirdHeader: "Committee (GTCC) of National Games Goa",
    },
    footer: {
      great: "With warm regards,",
      name: "Shri Amitabh Sharma",
      position: "Chairman of the National Games Technical Conduct",
      thirdHeader: "Committee (GTCC) of National Games Goa",
    },
    contents: [
      `From the Desk of Amitabh Sharma, Chairman of the National Games Technical Conduct Committee (GTCC) of National Games Goa`,
      `As we gear up for the 37th National Games in picturesque Goa, it's my honour to highlight the pivotal role of the National Games Technical Conduct Committee (GTCC). Our prime focus? Precision in every technical facet of the games, ensuring an impeccable sports experience in synergy with the National Games Organising Committee, Government of Goa.`,
      `From overseeing sports equipment needs to managing their timely arrival, from ensuring punctual match commencements to managing a top-tier team of officials like referees and timekeepers, the GTCC's reach is vast. We're driven to craft an environment where athletes, unburdened by technicalities, shine at their zenith.`,
      `Our team has designed each process for maximum efficiency. Be it minute specifics or grand showcases, our commitment is unwavering to make the 37th National Games a beacon of sports excellence.`,
      `As the games approach, our anticipation matches yours. Athletes have honed their skills, and Goa stands ready as their spotlight. The GTCC pledges the utmost in standards, aiding this colossal event's success.`,
      `Join us in this celebration of sports and excellence. Together, let's etch the 37th National Games into India's sporting annals.`,
    ],
  },
  {
    route: "Swetika",
    image: swetika,
    background: bg6,
    reverse: true,
    border: "#E6DDC7",
    header: {
      name: "Smt Swetika Sachan",
      position: "Secretary (Sports) & CEO, NGOC",
    },
    footer: {
      great: "With warm regards,",
      name: "Smt Swetika Sachan",
      position: "Secretary (Sports) & CEO, NGOC",
    },
    contents: [
      `From the Desk of Secretary (Sports) & CEO, NGOC Smt Swetika Sachan`,
      `Warm Greetings to Everyone!`,
      `As we draw closer to the inauguration of the 37th National Games, I am thrilled to extend a heartfelt welcome to each participant and attendee. It is an honour to be a part of this monumental event. I would like to shed light on our meticulous preparations that have gone into ensuring that the games in Goa are a benchmark of excellence, thanks to the collaborative efforts of the Government of Goa, the unwavering support from the Ministry of Youth Affairs and Sports, and the commitment of the Indian Olympic Association.`,
      `Together with the state government, our team has worked relentlessly to transform this into a spectacular sporting event. Across 43 disciplines, our commitment to achieve the pinnacle of excellence remains steadfast. Every venue adheres to international standards, ensuring athletes have the ideal environment to showcase their talents.`,
      `Our foremost concern is the well-being and comfort of every athlete. With comprehensive living arrangements and nutrition-focused meals, every aspect has been meticulously crafted. Our message to the athletes is simple: dedicate yourself to your sport, and let us take care of everything else.`,
      `Goa, with its harmonious blend of tradition and modernity, is eagerly anticipating your arrival. I urge you to dive into the richness of our state's heritage, its captivating culture, and the infectious spirit of fellowship.`,
      `To all the athletes: this is more than just a competition; it is an opportunity to etch your names in the annals of the National Games' rich legacy. Your efforts will light the path for future aspirants. Goa stands prepared, and we are eager to savour each milestone alongside you.`,
      `Thank you!`,
    ],
  },
  {
    route: "Geeta",
    image: geetha,
    background: bg1,
    reverse: true,
    border: "#CDD8F0",
    header: {
      name: "Dr. Geeta S. Nagvenkar",
      position: "Jt. CEO NGOC and Executive Director",
      thirdHeader: "Sports Authority of Goa",
    },
    footer: {
      great: "With warm regards,",
      name: "Dr. Geeta S. Nagvenkar",
      position: "Jt. CEO NGOC and Executive Director",
      thirdHeader: "Sports Authority of Goa",
    },
    contents: [
      `From the Desk of Jt. CEO NGOC and Executive Director, Sports Authority of Goa Dr. Geeta S. Nagvenkar`,
      `Greetings everyone!`,
      `As we look forward to the 37th National Games, Goa 2023, I warmly welcome you all. Officiating as the Joint CEO of NGOC and Executive Director of the Sports Authority of Goa is a privilege, and I want to share the immense effort and planning behind this event.`,
      `With 43 sports disciplines in the spotlight, our preparation has been thorough. Each venue is of top-notch standard, guaranteeing athletes world-class facilities and an environment to facilitate peak performance.`,
      `It's not just about facilities; it's about the entire experience that would be athlete centric. From your very arrival in Goa, we aim for you to feel our hospitality, culture, and state's energy. We've ensured that accommodation, food, and travel are of the highest quality.`,
      `I would sincerely like to encourage all participating athletes to give their best. The 37th National Games, Goa 2023 isn't just a contest; it's a festivity of sports and unity with Sports Legacy that is already envisaged to boost the States Sports Ecosystem. Your efforts will inspire many and further the spirit of sports in India.`,
      `Goa is set to warmly welcome you to this extraordinary sports extravaganza. Let's unite, celebrate sports, and make lasting memories.`,
      `Thank you!`,
    ],
  },
];

export const sponsorsLists = [
  {
    route: "about-games-partners",
    title: "Games Partners",
    list: [
      { image: goa, value: "GOVERNMENT OF GOA" },
      { image: sag, value: "THE SPORTS AUTHORITY OF GOA" },
      {
        image: ministry,
        value: "MINISTRY OF YOUTH AFFAIRS AND SPORTS GOVERNMENT OF INDIA",
      },
      { image: IOA, value: "INDIAN OLYMPIC ASSOCIATION" },
    ],
  },
  {
    route: "about-supported-by",
    title: "Supported by",
    list: [
      { image: ONGC, value: "ONGC" },
      { image: IndianOil, value: "INDIAN OIL" },
      { image: CoalIndia, value: "COAL INDIA LIMITED" },
    ],
  },
  {
    route: "about-associate-sponsors",
    title: "Associate Sponsors",
    list: [
      { image: NLC, value: "NCL" },
      { image: Powergrid, value: "POWERGRID" },
      { image: PFC, value: "PFC" },
      { image: FITSUPPORT, value: "FITSUPPORT" },
      { image: SAIL, value: "SAIL" },
    ],
  },
  {
    route: "about-partners",
    title: "Partners",
    list: [
      { image: T10sports, value: "T10 SPORTS" },
      { image: Paytm, value: "PAYTM" },
      { image: Spartan, value: "SPARTAN" },
      { image: COSCO, value: "COSCO" },
      { image: Bisleri, value: "BISLERI" },
    ],
  },
  {
    route: "about-broadcast-partner",
    title: "Broadcast Partner",
    list: [
      { image: DD_Sports, value: "DD SPORTS" },
      { image: jiocinema, value: "JIO CINEMA" },
    ],
  },
];

export const gamePartners = [
  { image: goa, value: "GOVERNMENT OF GOA" },
  { image: sag, value: "THE SPORTS AUTHORITY OF GOA" },
  {
    image: ministry,
    value: "MINISTRY OF YOUTH AFFAIRS AND SPORTS GOVERNMENT OF INDIA",
  },
  { image: IOA, value: "INDIAN OLYMPIC ASSOCIATION" },
];

export const SupportedBy = [
  { image: ONGC, value: "ONGC" },
  { image: IndianOil, value: "INDIAN OIL" },
  { image: CoalIndia, value: "COAL INDIA LIMITED" },
];
export const associateSponsors = [
  { image: NLC, value: "NCL" },
  { image: Powergrid, value: "POWERGRID" },
  { image: PFC, value: "PFC" },
  { image: FITSUPPORT, value: "FITSUPPORT" },
  { image: SAIL, value: "SAIL" },
];

export const partners = [
  { image: T10sports, value: "T10 SPORTS" },
  { image: Paytm, value: "PAYTM" },
  { image: Spartan, value: "SPARTAN" },
  { image: COSCO, value: "COSCO" },
  { image: Bisleri, value: "BISLERI" },
];

export const broadcastPartner = [
  { image: DD_Sports, value: "DD SPORTS" },
  { image: jiocinema, value: "JIO CINEMA" },
];

export const venueDetails = [
  {
    value: "panjim",
    list: [
      {
        title: "Panjim",
        venue: "Campal Multi-Purpose Indoor Stadium",
        address: "FRQ6+MH6, Campal, Panaji, Goa",
        image: "panjim1",
        location: "https://maps.app.goo.gl/hvNUxquU3KLtAWuM7",
        description: `Campal Indoor Complex is a multipurpose indoor stadium in Campal, Goa, India. The stadium is a main stadium in the town of Campal.t has various sports facilities like physical training for boys and ladies, indoor games, swimming pools, outdoor games etc. The stadium is the home of Sports Authority of Goa.`,
      },
      {
        title: "Panjim",
        venue: "FRV8+69P, Panaji, Goa 403001",
        address: "FRV8+69P, Panaji, Goa 403001",
        image: "panjim2",
        location: "https://maps.app.goo.gl/YTaua9WNYLhMcZd69",
        description: `Campal Open Ground, also known as Campal Sports Village, is a versatile open space in Goa that serves as a hub for various outdoor sports and recreational activities, fostering a vibrant sports culture in the heart of the city.`,
      },
      {
        title: "Panjim",
        venue: "Caranzalem - Miramar Road",
        address: "Caranzalem, Taleigao, Goa 403002",
        image: "panjim3",
        location: "https://maps.app.goo.gl/kBw1pdG5D87FmH8y8",
        description: `Caranzalem (pronounced Caranzale the alphabet m is silent) is a neighborhood located on the west side of the city of Panaji,
        capital of the Indian state of Goa. It is completely located on the island of Tiswadi, one of the talukas in the state of Goa
        `,
      },
      {
        title: "Panjim",
        venue: "Swimming Pool Campal",
        address: "FRQ7+M4R, Miramar, Panaji, Goa 403001",
        image: "panjim4",
        location: "https://maps.app.goo.gl/eYXTDQfweJ7ubRBG6",
        description: `The Swimming Pool in Campal, Goa, is a well-maintained aquatic facility offering a refreshing oasis for residents and visitors to enjoy swimming and water-based activities in a convenient urban setting.`,
      },
      {
        title: "Panjim",
        venue: "Hawaii Beach, Dona Paula",
        address: "Dona Paula, Goa, India, 403004",
        image: "panjim5",
        location: "https://maps.app.goo.gl/8JTnmmzRXWDjNXNr6",
        description: `Hawaii Beach in Dona Paula, Goa, is a serene coastal paradise known for its tranquil ambiance, making it a popular spot for relaxation and seaside strolls along the Arabian Sea.`,
      },
      {
        title: "Panjim",
        venue: "Miramar Beach, Varca Beach",
        address: "Fatrade, Varca, Benaulim, Goa, 403721",
        image: "panjim6",
        location: "https://maps.app.goo.gl/xLBabv1fUZDg7eaE8",
        description: `Miramar Beach was once known as Gasper Dias Beach. A short distance away from Panaji, Goa’s capital city, Miramar is a family-friendly beach but also a perfect place for groups of friends to hangout as well as fitness lovers who can enjoy morning and evening walks/run or yoga sessions here.`,
      },
      {
        title: "Panjim",
        venue: "S.P.M. Stadium, Goa University",
        address: "Goa University, Taleigao, Goa, 403206",
        image: "panjim7",
        location: "https://maps.app.goo.gl/jiNT15dZCvAj9Sor8",
        description: `The stadium was constructed for the 2014 Lusophony Games for events of volleyball and basketball. The stadium is named after Dr Shyama Prasad Mukherjee former Indian politician and the founder of Bhartiya Jana Sangh.
        The stadium has a seating capacity of 4000 and was inaugurated by Governor of Goa Bharat Vir Wanchoo in 2014.
        `,
      },
      {
        title: "Panjim",
        venue: "Athletics Stadium, Bambolim",
        address: "FV96+4WM, Bambolim, Goa 403202",
        image: "panjim8",
        location: "https://maps.app.goo.gl/3ThDWt7nTKPBB7Nd6",
        description: `The GMC Athletic Stadium in Bambolim is the first integrated athletic complex in Goa.[2] It has been constructed according to IAAF specifications and norms and has hosted the athletic events of 2014 Lusofonia Games. The field is covered with natural turf and has been used as the training ground for the FC Goa developmental squad.`,
      },
    ],
  },
  {
    value: "mapusa",
    list: [
      {
        title: "Mapusa",
        venue: "Hockey Ground, Peddem Sports Complex",
        address:
          "Shop No 4 Ground Floor Satt Adhar Arcade, Peddem, Karaswada, Mapusa, Goa 403507",
        image: "mapusa1",
        location: "https://maps.app.goo.gl/hsFETWLTBrvcYXDn9",
        description: `The hockey stadium at Peddem Sports complex in Mapusa will be named after hockey legend, Major Dhyan Chand, said Chief Minister Pramod Sawant on Sunday. He was speaking on the birth anniversary of Major Dhyan Chand which is celebrated as National Sports Day in India.`,
      },
      {
        title: "Mapusa",
        venue: "Indoor Hall A,  Peddem Sports Complex",
        address:
          "Shop No 4 Ground Floor Satt Adhar Arcade, Peddem, Karaswada, Mapusa, Goa 403507",
        image: "mapusa2",
        location: "https://maps.app.goo.gl/LuekA7fKfazQhdsL7",
        description: `The Peddem Sports Complex currently has a Multipurpose Indoor Hall with a stage, three wooden badminton courts, besides facilities for table tennis, judo, gymnastics, taekwondo.
        The 400 mts synthetic track, hockey playfield, indoor boxing training centre, indoor dojo for judo, shooting range, cricket turf-wickets with indoor practice wickets, sports sciences and research centre are additional facilities which are under process and are being developed on a top priority basis.
        `,
      },
      {
        title: "Mapusa",
        venue: "Peddem Multi-Purpose Indoor Stadium",
        address:
          "Shop No 4 Ground Floor Satt Adhar Arcade, Peddem, Karaswada, Mapusa, Goa 403507",
        image: "mapusa3",
        location: "https://maps.app.goo.gl/LuekA7fKfazQhdsL7",
        description: `The Peddem Sports Complex currently has a Multipurpose Indoor Hall with a stage, three wooden badminton courts, besides facilities for table tennis, judo, gymnastics, taekwondo.
        The 400 mts synthetic track, hockey playfield, indoor boxing training centre, indoor dojo for judo, shooting range, cricket turf-wickets with indoor practice wickets, sports sciences and research centre are additional facilities which are under process and are being developed on a top priority basis.`,
      },
      {
        title: "Mapusa",
        venue: "Chapora River",
        address: "Chapora Goa 403519",
        image: "mapusa4",
        location: "https://maps.app.goo.gl/r1LD2cFc7SLuLD2A6",
        description: `The river is located in the northernmost part of Goa. Originating from a small village in Maharashtra called Hajgoli near the town of Belgaum, the river Chapora flows through Tilari ghat and then enters Goa. Flowing into the Arabian sea of the Indian Ocean, towards the south of the river is Vagator Beach.`,
      },
    ],
  },
  {
    value: "margao",
    list: [
      {
        title: "Margao",
        venue: "Manohar Parrikar Indoor Stadium",
        address: "Sanscar Society, Margao, Goa 403707",
        image: "margao1",
        location: "https://maps.app.goo.gl/Hrat45aEm2Jok1x28",
        description: `Manohar Parrikar Indoor Stadium in Navelim, Goa, is a modern sports facility that hosts a wide range of indoor sports events and activities, serving as a hub for athletic excellence in the region.`,
      },
      {
        title: "Margao",
        venue: "PJN Stadium, Fatorda",
        address: "7XQ6+QW4, Fatorda, Goa 403602",
        image: "margao2",
        location: "https://maps.app.goo.gl/pFnT98ebeay8A6Nf7",
        description: `The PJN Fatorda Stadium, located in Goa, is a modern sporting arena known for hosting football matches and events, providing a vibrant hub for sports enthusiasts and fans.`,
      },
      {
        title: "Margao",
        venue: "Fatorda Multi-Purpose Hall",
        address: "Swimming Pool, Fatorda, SH 5, Fatorda, Margao, Goa 403602",
        image: "margao3",
        location: "https://maps.app.goo.gl/STZSpdi3VCkPhqxz8",
        description: `The Fatorda Multi-Purpose Hall, situated in Goa, is a versatile indoor facility serving as a venue for various events, from sports competitions to cultural gatherings, catering to the diverse needs of the community.`,
      },
      {
        title: "Margao",
        venue: "Multi-Purpose Ground, Fatorda",
        address: "7XQ6+QW4, Fatorda, Goa 403602",
        image: "margao4",
        location: "https://maps.google.com/?cid=7915290170421240143&entry=gps",
        description: `The Multi-Purpose Ground in Fatorda, Goa, is a versatile outdoor venue, hosting a wide range of events, from sports tournaments to cultural festivals, in the heart of the vibrant Goan community.`,
      },
      {
        title: "Margao",
        venue: "Colva Beach - Varsa",
        address: "Domnicks restaurant, Colva Beach Rd, Colva, Goa 403708",
        image: "margao5",
        location: "https://maps.app.goo.gl/ktehHEWN7iPTkQF87",
        description: `Colva Beach in Goa, India, is a serene and picturesque coastal paradise, renowned for its golden sands and tranquil ambiance, making it a perfect destination for relaxation and seaside enjoyment.`,
      },
    ],
  },
  {
    value: "vasco",
    list: [
      {
        title: "Vasco",
        venue: "Verna - Birla Byepass Airport Road",
        address: "Kesarvale, Goa 403710",
        image: "vasco1",
        location: "https://maps.google.com/?cid=3150974508393265485&entry=gps",
        description: `The Verna-Birla Bypass Airport Road is a crucial transportation route in Goa, connecting Verna and Birla with efficient access to nearby destinations and amenities.`,
      },
      {
        title: "Vasco",
        venue: "Chicalim Squash Facility",
        address:
          "9RRM+W97, NH566, Adarsh Nagar, Chicalim, New Vaddem, Goa 403711",
        image: "vasco2",
        location: "https://maps.app.goo.gl/XAbCU7jRwQuwR9ux6 ",
        description: `The Chicalim Squash Facility is a state-of-the-art sports center in Goa, providing top-notch infrastructure and coaching for squash enthusiasts, fostering a dynamic squash community in the region.`,
      },
      {
        title: "Vasco",
        venue: "Chicalim Multi - purpose Ground",
        address:
          "9RRM+W97, NH566, Adarsh Nagar, Chicalim, New Vaddem, Goa 403711",
        image: "vasco3",
        location: "https://maps.app.goo.gl/XAbCU7jRwQuwR9ux6",
        description: `Chicalim Multi-purpose Ground is a versatile sports and event venue in Goa, offering ample space for various outdoor activities and gatherings, catering to both sports enthusiasts and community events.`,
      },
      {
        title: "Vasco",
        venue: "Tilak Maidan Football Ground",
        address: "Vaddem, New Vaddem, Mormugao, Goa 403802",
        image: "vasco4",
        location: "https://maps.app.goo.gl/5HeWv4s6phQD7bqv7",
        description: `The venue has the capacity to accommodate 5,000 spectators.[5] The Group stage of the football event during 2014 Lusofonia Games was held here.[6] The stadium is maintained by the Sports Authority of Goa.`,
      },
    ],
  },

  {
    value: "ponda",
    list: [
      {
        title: "Ponda",
        venue: "Ponda Multi-Purpose Indoor Stadium",
        address:
          "NH748, Varkhande, Electricity Dept Staff Residential Colony, Ponda, Goa 403401",
        image: "ponda1",
        location: "https://maps.app.goo.gl/NqC8VUDUUw5dZPts8",
        description: `Ponda Multi-Purpose Indoor Stadium in Goa is a versatile sports and event venue, offering a covered space for various activities and gatherings, catering to the community's diverse needs.`,
      },
      {
        title: "Ponda",
        venue: "Goa Engineering Collage, Farmagudi - Ponda",
        address:
          "Bhausaheb Bandodkar Technical Education Complex Farmagudi, Ponda, Goa 403401",
        image: "ponda2",
        location: "https://maps.google.com/?cid=12851243738651322540&entry=gps",
        description: `Goa Engineering College in Farmagudi, Ponda, is a prestigious institution known for its excellence in engineering education and research, offering a vibrant learning environment in the picturesque state of Goa, India.`,
      },
    ],
  },

  {
    value: "delhi",
    list: [
      {
        title: "Delhi",
        venue: "Indira Gandhi Stadium Complex, New Delhi",
        address:
          "Indraprastha Estate, Grand Trunk Rd, near Raj Ghat, IG Indoor Stadium, ITO, IP Estate, New Delhi, Delhi, 110002",
        image: "delhi1",
        location: "https://maps.app.goo.gl/j8f1hPGpZouJt88L8",
        description: `The Indira Gandhi Arena, formerly known as the Indraprashtha Stadium, is located at the Indraprastha Estate in the eastern region of New Delhi. It is the largest indoor sports arena in India and among the largest in Asia.`,
      },
    ],
  },
];
